import { useEffect } from 'react'
import { useLocation }                                             from 'react-router-dom'
import {initializeGA, setUserId, setUserProperties, trackPageView} from '../util/analytics'

const Analytics = ({user, isAdmin}) => {
	const location = useLocation()

	useEffect(() => {
		initializeGA(process.env.REACT_APP_GTAG) // Initialize GA once when the component mounts
	}, [])

	useEffect(() => {
		setUserId(user?.id)
	}, [user, isAdmin])

	useEffect(() => {
		trackPageView(location.pathname + location.search) // Track page view on location change
	}, [location])

	return null // This component doesn't render anything
}

export default Analytics