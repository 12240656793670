import React, {useMemo} from 'react'
import {logger}         from "../../util/log-utils"
import {Box, Checkbox, Collapse, ListItem, Typography, useTheme} from "@mui/material"
import {abcd}                                                    from "../../util/prepkit-utils"
import Validations                                               from "./Validations"
import LeftRight                                                 from "../LeftRight"

const log = logger("QuestionListItem", 1)

function QuestionListItem({question, selected, index, onEdit, onSelect, expanded}) {
	const theme = useTheme()

	const isInvalid = useMemo(() => {
		return question.validations &&
			question.validations.length > 0 &&
			question.validations.some(validation => validation.validation_type === 'ACCURACY' &&  !validation.is_valid)
	}, [question])

	return (
		<ListItem
			key={question.id}
			sx={{
				p: 0,
				m: 0,
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-start",
				backgroundColor: selected ? theme.palette.action.selected : 'inherit' // Highlight row when selected
			}}
		>
			<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
				<LeftRight
					left={
						<Box>
							<Checkbox
								checked={selected}
								onChange={() => onSelect(!selected)}
								sx={{ mr: 2 }} // Add some margin between checkbox and question
							/>
							<Typography variant={"body"}
							            sx={{
											cursor: "pointer",
								            color: isInvalid ? theme.palette.warning.main : "inherit"
										}}
							            onClick={() => onEdit && onEdit(question, index)}
							>
								{(index + 1) + ". " + question.question + ` [${question.code}]`}
							</Typography>
						</Box>
					}
					right={
						question.validations &&
						question.validations.length > 0 &&
						<Validations validations={question.validations} />
					}
				/>

			</Box>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<Box
					sx={{
						my: 1,
						mx: 2,
						p: 3,
						pb: 1,
						border: "1px solid #ddd",
						backgroundColor: theme.palette.background.paper
					}}
				>
					{question.options.map((o, i) => {
						const isCorrect = i === question.answer
						return (
							<div
								key={`opt-${question.id}-${i}`}
								style={{
									padding: '3px',
									color: isCorrect
										? theme.palette.success.light
										: theme.palette.text.primary,
									fontWeight: isCorrect
										? 'bold'
										: 'normal'
								}}
							>
								{abcd(i)}) {o.label}
							</div>
						)
					})}
					<div style={{ marginTop: "10px" }}>
						{question.explanation}
					</div>
				</Box>
			</Collapse>
		</ListItem>
	)
}

export default QuestionListItem
