import React, {useState}                                                    from 'react'
import {Box, Chip, Divider, IconButton, Menu, MenuItem, Tooltip, useTheme,} from '@mui/material'
import LeftRight                                                            from "../LeftRight"
import {MoreVert, MoveUp, Verified} from "@mui/icons-material"
import ValidationSummary            from "./ValidationSummary"
import MoreVertIcon                                          from "@mui/icons-material/MoreVert"

function TopicsTree({topics, depth, selectedTopic, onClick, onMoveTopicUp, onValidateTopic}) {

	if (!topics || topics.length === 0) return null

	depth = depth || 0

	return (
		<ul style={{
			paddingLeft: depth ? "25px" : "10px",
			listStyle: "default",
		}}>
			{topics.map((t) => <TopicTreeNode key={t.id}
			                                  topic={t}
			                                  selectedTopic={selectedTopic}
			                                  onMoveTopicUp={onMoveTopicUp}
			                                  onValidateTopic={onValidateTopic}
			                                  onClick={onClick}
			                                  depth={depth}
			/>)}
		</ul>
	)
}

const TopicTreeNode = ({topic, selectedTopic, depth, onClick, onMoveTopicUp, onValidateTopic}) => {
	const theme = useTheme()
	const [anchorEl, setAnchorEl] = useState(null)
	const menuOpen = Boolean(anchorEl)

	const handleMenuClick = (event) => {
		event.stopPropagation() // Prevent triggering onClick of the parent div
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const handleMoveUp = () => {
		handleMenuClose()
		onMoveTopicUp && onMoveTopicUp(topic)
	}

	const handleValidate = (onlyValidateInvalidOrNotValidatedQuestions) => {
		handleMenuClose()
		onValidateTopic && onValidateTopic(topic, onlyValidateInvalidOrNotValidatedQuestions)
	}

	const t = topic
	const isLeaf = t.hasOwnProperty('is_leaf')
		? t.is_leaf === true
		: !t.topics

	const questionsCount = isLeaf
		? (t.questions && t.questions.length) || 0
		: 0

	const questionsLabel = `${questionsCount} / ${t.target_question_count}`
	const questionsTooltip = `${questionsCount} questions, ${t.target_question_count} targeted`
	const selected = selectedTopic && selectedTopic.id === t.id
	const chipColor = questionsCount > 0 && questionsCount >= t.target_question_count
		? "success"
		: "default"

	return (
		<li style={{
			padding: "5px",
			paddingRight: "0px",
			fontWeight: t.parent_id === null ? "bold" : "normal",
			borderBottomStyle: "solid",
			borderBottomColor: theme.palette.divider,
			borderBottomWidth: depth === 0 ? "1px" : "0px"
		}}
		    key={t.id}
		>
			<div style={{
				padding: "3px",
				cursor: "pointer",
				fontWeight: selected || depth === 0
					? "bold"
					: "normal",
			}}
			     onClick={() => {
				     onClick && onClick(t)
			     }}
			>
				<LeftRight
					left={
						<Box>
							{t.name}
							{
								isLeaf &&
								<Tooltip title={questionsTooltip} enterDelay={1000} enterNextDelay={1000}>
									<Chip
										sx={{ml: 1}}
										label={questionsLabel}
										size="small"
										variant={"outlined"}
										color={chipColor}
									/>
								</Tooltip>
							}
						</Box>
					}
					right={
						<Box>
							<Box display={"flex"}
							     flexDirection={"row"}
							     alignItems={"center"}
							>
								<ValidationSummary validations={t.validations}/>
								<IconButton
									aria-label="more"
									aria-controls={menuOpen ? 'long-menu' : undefined}
									aria-haspopup="true"
									onClick={handleMenuClick}
								>
									<MoreVert />
								</IconButton>
								<Menu
									id="long-menu"
									anchorEl={anchorEl}
									open={menuOpen}
									onClose={handleMenuClose}
									onClick={(e) => e.stopPropagation()}
								>
									<MenuItem onClick={()=>handleValidate(true)}>
										<Verified fontSize="small" style={{ marginRight: '8px' }} />
										Validate Invalid/Unvalidated
									</MenuItem>
									<MenuItem onClick={()=>handleValidate(false)}>
										<Verified fontSize="small" style={{ marginRight: '8px' }} />
										Validate All
									</MenuItem>
									<Divider/>
									{
										onMoveTopicUp &&
										depth > 0 &&
										<MenuItem onClick={handleMoveUp}>
											<MoveUp fontSize="small" style={{ marginRight: '8px' }} />
											Move Up
										</MenuItem>
									}
								</Menu>
							</Box>
						</Box>
					}
				/>

			</div>
			{
				t.topics &&
				t.topics.length > 0 &&
				<TopicsTree topics={t.topics}
			                selectedTopic={selectedTopic}
			                onClick={onClick}
			                onMoveTopicUp={onMoveTopicUp}
			                onValidateTopic={onValidateTopic}
			                depth={depth+1}
				/>
			}
		</li>
	)
}

export default TopicsTree
