import React, {useEffect, useState, useMemo} from 'react'
import getSupabase                  from "../../util/supabase-utils"
import {
	AlertTitle,
	Backdrop,
	Box,
	Button,
	Card,
	CardContent,
	Chip, CircularProgress,
	Container,
	Dialog, DialogActions,
	DialogContent,
	Divider,
	Link,
	List, ListItem,
	ListItemButton,
	Typography,
	useTheme
} from "@mui/material"
import styled            from "@emotion/styled"
import {Alert, Skeleton} from "@mui/material"
import BirdsyMiniLogo           from "../BirdsyMiniLogo"
import moment                    from "moment"
import {ArrowBack, AutoAwesome}  from "@mui/icons-material"
import {doGet, doPost}                    from "../../util/do-fetch"
import PackageListItem                    from "./PackageListItem"
import {fmt2Dec}                          from "../../util/prepkit-utils"
import LeftRight                          from "../LeftRight"
import Grid                               from "@mui/material/Unstable_Grid2"
import MiniCheckout                       from "./MiniCheckout"
import {Elements}                         from "@stripe/react-stripe-js"
import {loadStripe}                       from "@stripe/stripe-js"
import MiniSkeleton                       from "./MiniSkeleton"
import CircularProgressWithLabel          from "../CircularProgressWithLabel"
import {useMatch, useNavigate, useParams} from "react-router-dom"
import {trackPurchase}                    from "../../util/analytics"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let _stripePromise = null
const stripePromise = () => {
	if (!_stripePromise) {
		_stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
	}
	return _stripePromise
}

const RenewPrepKit = ({prepKit, id, onPurchaseComplete}) => {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const { categoryToken, packageToken, optionId } = useParams()
	const [renewPackage, setRenewPackage] = useState(null)
	const [similarPackages, setSimilarPackages] = useState(null)
	const [clientSecret, setClientSecret] = useState(null)
	const [purchaseComplete, setPurchaseComplete] = useState(false)

	const selectedPackage = useMemo(()=>{
		if(renewPackage && renewPackage.token === packageToken){
			return renewPackage
		}
		if(similarPackages && similarPackages.length > 0) {
			const match = similarPackages.find(p => p.token === packageToken)
			if(match) return match
		}
		return null
	}, [packageToken, renewPackage, similarPackages])

	const selectedOption = useMemo(()=>{
		if(selectedPackage && selectedPackage.options && selectedPackage.options.length > 0){
			return selectedPackage.options.find(o => o.id === optionId)
		}
		return null
	}, [optionId, selectedPackage])

	useEffect(()=>{
		fetchRenewalOptions()
	}, [prepKit])

	useEffect(() => {
		if(selectedPackage && selectedOption) {
			const order = {
				packageId: selectedPackage.id,
				optionId: selectedOption.id,
				prepKitId: prepKit.id
			}
			const options = {body: JSON.stringify(order)}
			doPost(`/mini/create-payment-intent`, options)
				.then(res => res.json())
				.then(data => setClientSecret(data.clientSecret))
		}
		else {
			setClientSecret(null)
		}
	}, [selectedPackage, selectedOption])

	const fetchRenewalOptions = async () => {

		if(!prepKit) return

		setLoading(true)
		try {
			const url = `/mini/prepkit/${prepKit.id}/renewal-options`
			const response = await doGet(url)

			if (response.ok) {
				const data = await response.json()
				setRenewPackage(data.renewPackage)
				setSimilarPackages(data.similarPackages)
				setLoading(false)
			}
			else if (response.status === 400) {
				const errorData = await response.json()
				console.error(errorData)
				throw Error("Error loading packages.")
			}
			else {
				// Handle other error cases
				console.error('An error occurred:', response.statusText)
				throw Error("Error loading packages.")
			}
		}
		catch(e) {
			console.error("Error loading packages.")
		}
	}

	const handleSelectRenewPackage = (pkg) => {
		if(pkg) {
			navigate(`/prepkit/${prepKit.id}/renew/${pkg.category.token}/${pkg.token}`)
		}
		else {
			navigate(`/prepkit/${prepKit.id}`)
		}
	}

	const handleSelectSimilarPackage = (pkg) => {
		/*
		 * it is possible that the user selected a package that is different from the prepkit.package_id that we
		 * are currently in.  For example, they may be in National Real Estate Exam Prep, but want to register for
		 * Missouri Real Estate Exam Prep.
		 *
		 * Or, maybe their current prepKit's package is expired so they have to purchase a different package
		 *
		 * In this case, we may need to send them over to the purchase experience
		 */
		navigate(`/prep/${pkg.category.token}/${pkg.token}`)
	}

	const handleSelectOption = (pkg, opt) => {
		if(pkg && opt) {
			navigate(`/prepkit/${prepKit.id}/renew/${pkg.category.token}/${pkg.token}/option/${opt.id}`)
		}
		else if(pkg) {
			navigate(`/prepkit/${prepKit.id}/renew/${pkg.category.token}/${pkg.token}`)
		}
		else {
			navigate(`/prepkit/${prepKit.id}/renew`)
		}

	}

	const handlePurchaseComplete = (paymentIntent) => {
		setPurchaseComplete(true)

		try {
			trackPurchase(paymentIntent.id, selectedPackage, selectedOption)
		}
		catch(e) {
			// don't let this break functionality
			console.error("Error sending purchase to Google Analytics")
			console.error(e)
		}

		onPurchaseComplete && onPurchaseComplete(paymentIntent)
		// todo: do something with the paymentIntent
	}

	if (!prepKit) {
		return null
	}

	let dialogContent = null
	let dialogWidth = 500

	if(purchaseComplete) {
		dialogContent = <Box>
			<Alert severity="success">
				Payment succeeded! Thank you for your purchase.
			</Alert>
			<Box py={2} textAlign="center">
				<Button
					variant="outlined"
					size="large"
					onClick={()=>navigate('/prepkit')}
				>
					Continue
				</Button>
			</Box>
		</Box>
	}
	else if (selectedPackage && selectedOption) {
		dialogContent = <Elements stripe={stripePromise()}>
			<Box>
				<MiniCheckout clientSecret={clientSecret}
				              selectedPackage={selectedPackage}
				              selectedOption={selectedOption}
				              onPurchaseComplete={handlePurchaseComplete}
				/>
				<Divider/>
				<Box mt={2}>
					<Button size="large"
					        onClick={() => handleSelectOption(selectedPackage, null)}>
						<ArrowBack/> Back
					</Button>
				</Box>
			</Box>
		</Elements>
	}
	else if (selectedPackage) {
		dialogWidth = 800
		dialogContent = <Box>
			<SelectOptionList selectedPackage={selectedPackage}
			                  onSelectOption={handleSelectOption}/>
			<Box>
				<Button size="large"
				        onClick={() =>  handleSelectOption(null, null)}>
					<ArrowBack/> Back
				</Button>
			</Box>
		</Box>
	}
	else {
		dialogContent = <Box>
			<Box pb={2}>
				<Typography variant="h6">
					Extend your PrepKit.
				</Typography>
				<Typography variant="body2">
					Select your PrepKit to continue.
				</Typography>
			</Box>
			{
				loading &&
				<Box display={"flex"}
				     justifyContent={"center"}
				     alignItems={"flex-start"}
				     minHeight={100}
				     pt={4}
				>
					<CircularProgress size={18} sx={{mr: 1}}/> Loading
				</Box>
			}
			{
				renewPackage &&
				<SelectPackageList packages={[renewPackage]}
				                   onSelectPackage={handleSelectRenewPackage}
				                   showPricing={false}
				/>
			}
			{
				similarPackages &&
				similarPackages.length > 0 &&
				<Box>
					<Alert severity={"info"}>
						<AlertTitle>Updated PrepKit</AlertTitle>
						The PrepKit you are renewing is no longer available.  Please choose from one of the updated
						PrepKit packages below.
					</Alert>
					<SelectPackageList packages={similarPackages}
					                   onSelectPackage={handleSelectSimilarPackage}
					                   showPricing={false}
					/>
				</Box>
			}
			<Box>
				<Button size="large"
				        onClick={() => navigate('/prepkit')}>
					<ArrowBack/> Back
				</Button>
			</Box>
		</Box>
	}

	return (
		<Container style={{maxWidth: '800px', textAlign: 'center'}}>
			<MiniSkeleton title={prepKit && prepKit.name}/>
			<Dialog
				open={true}
				maxWidth={`${dialogWidth + 50}px`}
				BackdropProps={{
					style: {
						backdropFilter: 'blur(5px)',
					}
				}}
			>
				<Box p={3} pb={1} border={"0px solid red"}>
					<Box display="flex" justifyContent="center" pl={3}>
						<BirdsyMiniLogo width={125}/>
					</Box>
				</Box>
				<DialogContent sx={{width: dialogWidth, transition: 'width 550ms'}}>
					{dialogContent}
				</DialogContent>
			</Dialog>
		</Container>
	)
}

const SelectPackageList = ({packages, showPricing, onSelectPackage}) => {

	return <List>
			{
				packages &&
				packages.length > 0 &&
				packages.map(rp => <PackageListItem
					key={`related-${rp.id}`}
					packageData={rp}
					showPricing={showPricing}
					onClick={onSelectPackage}/>)

			}
		</List>
}

const SelectOptionList = ({selectedPackage, onSelectOption}) => {
	const theme = useTheme()

	return <Box>
		<Box p={2}>
			<Typography variant="h6" pl={1}>
				{selectedPackage.name} - <small>{selectedPackage.tagline}</small>
			</Typography>
			<Box pl={1}>
				<b>Includes: </b>
				<ul>
					{
						selectedPackage.products.map(p => <li key={`product-${p.id}`}>- {p.name}</li>)
					}
				</ul>
			</Box>
		</Box>
		<Grid container>
			{
				selectedPackage &&
				selectedPackage.options.map(opt => (
						<Grid xs={12} sm={4}
						      key={`option-${opt.id}`}
						      sx={{p: 1}}
						>
							<Card
								sx={{
									width: '100%',
									textAlign: "center",
									cursor: "pointer",
									'&:hover': {
										backgroundColor: theme.palette.action.hover,
									}
								}}
								onClick={() => onSelectOption(selectedPackage, opt)}
							>
								<CardContent sx={{height: '90px'}}>
									<Typography variant="h5">
										{opt.name}
									</Typography>
									<Typography variant="h6">
										${fmt2Dec(opt.price_in_cents / 100)}
									</Typography>
								</CardContent>
								<Box justifyContent="center" alignItems="center">
									<Button sx={{my: 2}}>
										Choose Plan
									</Button>
								</Box>
							</Card>
						</Grid>
					)
				)
			}
		</Grid>
	</Box>


	return <List sx={{border: `1px solid ${theme.palette.divider}`, p: 0}}>
		<PackageListItem
			key={`selected-${selectedPackage.id}`}
			packageData={selectedPackage}/>
		{
			selectedPackage &&
			selectedPackage.options.map(opt => (
					<ListItemButton
						key={`option-${opt.id}`}
						onClick={() => onSelectOption(selectedPackage, opt)}
					>
						<LeftRight
							left={<Typography><b>{opt.name}</b></Typography>}
							right={<Typography variant="h6">${fmt2Dec(opt.price)}</Typography>}
						/>

					</ListItemButton>
				)
			)

		}
	</List>
}

export default RenewPrepKit
