import React from 'react'
import { Modal, Box, CircularProgress, Typography } from '@mui/material'

const WorkingOverlay = ({ open }) => {
	return (
		<Modal open={open}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
				}}
			>
				<CircularProgress />
				<Typography
					sx={{
						marginTop: 2,
						color: 'white',
						fontWeight: 'bold',
					}}
				>
					Working
				</Typography>
			</Box>
		</Modal>
	)
}

export default WorkingOverlay
