import React, {useEffect, useMemo, useState} from 'react'
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	useTheme
}                                            from '@mui/material'

import {abcd}                             from "../../util/prepkit-utils"
import Grid                               from "@mui/material/Unstable_Grid2"
import {isBlankOrEmpty, isIntegerBetween} from "../../util/utils"
import CircleIndicator                    from "../CircleIndicator"
import {getAccuracyValidation}            from "../../util/admin"
import LeftRight                          from "../LeftRight"

const QuestionEditor = ({question, onSave, onCancel, onSaveAndValidate, onDelete}) => {
	const theme = useTheme()
	const [editedQuestion, setEditedQuestion] = useState(question ? question.question : '')
	const [editedOptions, setEditedOptions] = useState(question ? question.options : [])
	const [editedAnswer, setEditedAnswer] = useState(question ? question.answer.toString() : '')
	const [editedExplanation, setEditedExplanation] = useState(question ? question.explanation : '')
	const [show, setShow] = useState(false)
	const [saving, setSaving] = useState(false)
	const [showAllFieldsRequiredError, setShowAllFieldsRequiredError] = useState(false)

	useEffect(() => {
		setShow(question !== null)
		setEditedQuestion(question ? question.question : '')
		setEditedOptions(question ? question.options : [])
		setEditedAnswer(question ? question.answer.toString() : '')
		setEditedExplanation(question ? question.explanation : '')
	}, [question])

	useEffect(() => {
		if (!question) {
			setShowAllFieldsRequiredError(false)
			return
		}

		if (isBlankOrEmpty(editedQuestion) ||
			!isIntegerBetween(editedAnswer, 0, 3) ||
			isBlankOrEmpty(editedExplanation) ||
			editedOptions.some(o => isBlankOrEmpty(o.label))) {

			console.log({
				editedQuestion: isBlankOrEmpty(editedQuestion),
				editedAnswer: !isIntegerBetween(editedAnswer, 0, 3),
				editedExplanation: isBlankOrEmpty(editedExplanation),
				editedOptions: editedOptions.some(o => isBlankOrEmpty(o.label))
			})

			setShowAllFieldsRequiredError(true)
			return
		}

		setShowAllFieldsRequiredError(false)
	}, [question, editedQuestion, editedOptions, editedAnswer, editedExplanation])

	const editedAnswerInteger = useMemo(() => {
		return editedAnswer ? parseInt(editedAnswer, 10) : -1
	}, [editedAnswer])

	const accuracyValidation = useMemo(() => {
		const v = getAccuracyValidation(question)
		return v && !v.is_valid ? v : null
	}, [question])

	const accuracyValidationMessage = useMemo(() => {
		return accuracyValidation && accuracyValidation.validation.validation
	}, [accuracyValidation])

	const questionValidationRevision = useMemo(() => {
		if (accuracyValidation) {
			if (accuracyValidation.validation) {
				if (editedQuestion !== accuracyValidation.validation.question) {
					return accuracyValidation.validation.question
				}
			}
		}
		return null
	}, [editedQuestion, accuracyValidation])

	const optionAValidationRevision = useMemo(() => {
		if (accuracyValidation &&
			accuracyValidation.validation &&
			editedOptions &&
			accuracyValidation.validation.options > 0 &&
			editedOptions[0].label !== accuracyValidation.validation.options[0].label) {
			return accuracyValidation.validation.options[0].label
		}
		return null
	}, [editedOptions, accuracyValidation])

	const optionBValidationRevision = useMemo(() => {
		if (accuracyValidation &&
			accuracyValidation.validation &&
			editedOptions &&
			accuracyValidation.validation.options > 1 &&
			editedOptions[1].label !== accuracyValidation.validation.options[1].label) {
			return accuracyValidation.validation.options[1].label
		}
		return null
	}, [editedOptions, accuracyValidation])

	const optionCValidationRevision = useMemo(() => {
		if (accuracyValidation &&
			accuracyValidation.validation &&
			editedOptions &&
			accuracyValidation.validation.options > 2 &&
			editedOptions[2].label !== accuracyValidation.validation.options[2].label) {
			return accuracyValidation.validation.options[2].label
		}
		return null
	}, [editedOptions, accuracyValidation])

	const optionDValidationRevision = useMemo(() => {
		if (accuracyValidation &&
			accuracyValidation.validation &&
			editedOptions &&
			accuracyValidation.validation.options > 3 &&
			editedOptions[3].label !== accuracyValidation.validation.options[3].label) {
			return accuracyValidation.validation.options[3].label
		}
		return null
	}, [editedOptions, accuracyValidation])

	const explanationValidationRevision = useMemo(() => {
		if (accuracyValidation) {
			if (accuracyValidation.validation) {
				if (editedExplanation !== accuracyValidation.validation.explanation) {
					return accuracyValidation.validation.explanation
				}
			}
		}
		return null
	}, [editedExplanation, accuracyValidation])

	const answerValidationRevision = useMemo(() => {
		if (accuracyValidation) {
			if (accuracyValidation.validation) {
				// noinspection JSUnresolvedReference
				const answerIndex = accuracyValidation.validation.options &&
					accuracyValidation.validation.options.length
					? accuracyValidation.validation.options.findIndex(option => option.correctAnswer)
					: editedAnswerInteger
				if (editedAnswerInteger !== answerIndex) {
					return answerIndex
				}
			}
		}
		return null
	}, [editedAnswerInteger, accuracyValidation])

	const handleOptionChange = (index, newValue) => {
		const newOptions = editedOptions.map((option, i) => {
			if (i === index) {
				return {...option, label: newValue}
			}
			return option
		})
		setEditedOptions(newOptions)
	}

	const handleSave = async () => {
		if (!onSave) return
		setSaving(true)
		const updatedQuestion = {
			question: editedQuestion,
			options: editedOptions,
			answer: Number.parseInt(editedAnswer),
			explanation: editedExplanation,
		}
		await onSave(question, updatedQuestion)
		setSaving(false)
	}

	const handleSaveAndValidate = async () => {
		if (!onSaveAndValidate) return
		setSaving(true)
		const updatedQuestion = {
			question: editedQuestion,
			options: editedOptions,
			answer: Number.parseInt(editedAnswer),
			explanation: editedExplanation,
		}
		await onSaveAndValidate(question, updatedQuestion)
		setSaving(false)
	}

	const handleDelete = async () => {
		if (!onDelete) return
		setSaving(true)
		await onDelete(question)
		setSaving(false)
	}

	// noinspection JSValidateTypes
	return (
		question &&
		<Dialog
			open={show}
			maxWidth="lg"
		>
			<DialogTitle>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<span>Edit Question</span>

					<Box>
						ID: {question.id},
						CODE: {question.code}
					</Box>
				</Box>
			</DialogTitle>
			{
				accuracyValidationMessage &&
				<Alert severity="warning">
					<AlertTitle>Accuracy Validation Warning</AlertTitle>
					{accuracyValidationMessage}
				</Alert>
			}
			{
				showAllFieldsRequiredError &&
				<Alert severity="error">All fields are required.</Alert>
			}
			<DialogContent>
				<Grid container>
					<Grid xs={12}>
						<TextField
							fullWidth
							margin="normal"
							value={editedQuestion}
							onChange={(e) => setEditedQuestion(e.target.value)}
							inputProps={{
								style: {fontWeight: 'bold'}
							}}
						/>
						{
							questionValidationRevision &&
							<Alert severity="warning">
								{questionValidationRevision}
								<Button onClick={() => setEditedQuestion(questionValidationRevision)}>Apply</Button>
							</Alert>
						}
					</Grid>
					<Grid xs={12} container style={{margin: 0, padding: 0}}>
						<Grid xs={12}>
							<h4 style={{
								padding: 0,
								paddingRight: "10px",
								margin: 0,
								display: "inline-block"
							}}>
								Answer Options
							</h4>
						</Grid>
						{
							editedOptions.map((option, index) => {
								// noinspection JSUnresolvedReference
								const letterColor = editedAnswerInteger === index
									? theme.palette.score.success
									: theme.palette.text.secondary
								return <Grid
									xs={6}
									key={question.id + "-option-" + index}
									sx={{p: 0, pr: index % 2 === 0 ? 2 : 0}}
								>
									<div style={{
										display: 'flex',
										flexDirection: 'row',
									}}>
										<div style={{padding: '28px 15px 0 0'}}>
											<CircleIndicator character={abcd(index)} size={32}
											                 backgroundColor={letterColor}/>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'column',
											flex: 1,
										}}
										>
											<TextField
												sx={{width: '100%'}}
												variant="outlined"
												fullWidth
												margin="normal"
												value={option.label}
												onChange={(e) => handleOptionChange(index, e.target.value)}
											/>
											{
												index === 0 &&
												optionAValidationRevision &&
												<Alert severity="warning"
												       action={
													       <Button
														       onClick={() => handleOptionChange(index, optionAValidationRevision)}>Apply</Button>
												       }
												>
													{optionAValidationRevision}
												</Alert>
											}
											{
												index === 1 &&
												optionBValidationRevision &&
												<Alert severity="warning"
												       action={
													       <Button
														       onClick={() => handleOptionChange(index, optionBValidationRevision)}>Apply</Button>
												       }
												>
													{optionBValidationRevision}
												</Alert>
											}
											{
												index === 2 &&
												optionCValidationRevision &&
												<Alert severity="warning"
												       action={
													       <Button
														       onClick={() => handleOptionChange(index, optionCValidationRevision)}>Apply</Button>
												       }
												>
													{optionCValidationRevision}
												</Alert>
											}
											{
												index === 3 &&
												optionDValidationRevision &&
												<Alert severity="warning"
												       action={
													       <Button
														       onClick={() => handleOptionChange(index, optionDValidationRevision)}>Apply</Button>
												       }
												>
													{optionDValidationRevision}
												</Alert>
											}
											{/*
										<TextField
											sx={{width: '100%'}}
											label={`Insight`}
											variant="outlined"
											fullWidth
											margin="normal"
											value={option.insight}
											multiline={true}
											rows={3}
											onChange={(e) => handleInsightChange(index, e.target.value)}
										/>
										*/}
										</div>
									</div>
								</Grid>
							})}
					</Grid>

					<Grid xs={12} spacing={0}>
						<FormControl
							component="fieldset"
							margin="normal"
							sx={{
								m: 0,
								pt: 1,
							}}
						>
							<h4 style={{
								padding: 0,
								paddingRight: "10px",
								paddingBottom: "10px",
								margin: 0,
								display: "inline-block"
							}}>
								Correct Answer
							</h4>
							<ToggleButtonGroup
								value={editedAnswer}
								exclusive
								onChange={(event, newAnswer) => setEditedAnswer(newAnswer)}
								aria-label="text alignment"
								orientation="horizontal"
							>
								{editedOptions.map((option, index) => (
									<ToggleButton
										key={index}
										value={index.toString()}
										sx={{minWidth: "60px"}}
									>
										{abcd(index)}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
							{
								answerValidationRevision &&
								<Alert severity="warning">Answer should be {abcd(answerValidationRevision)}</Alert>
							}
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<h4 style={{
							padding: 0, margin: 0, marginTop: '15px',
							paddingBottom: "10px"
						}}>Explanation</h4>
						<TextField
							sx={{mt: 0}}
							fullWidth
							margin="normal"
							multiline
							maxRows={4}
							value={editedExplanation}
							onChange={(e) => setEditedExplanation(e.target.value)}
						/>
						{
							explanationValidationRevision &&
							<Alert severity="warning">
								{explanationValidationRevision}
								<Button
									onClick={() => setEditedExplanation(explanationValidationRevision)}>Apply</Button>
							</Alert>
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{p: 2, pt: 0}}>
				<LeftRight
					left={<Box>
						<Button size="large" color={"error"} variant="outlined"
						        onClick={handleDelete}
						        disabled={saving}>
							Delete
						</Button>
					</Box>}
					right={
						<Box>
							<Button size="large" onClick={() => onCancel && onCancel(question)}
							        disabled={saving}>
								Cancel
							</Button>
							<Button sx={{ml: 1}}  size="large" variant="contained" onClick={handleSave} disabled={saving}>
								{saving ? "Saving" : "Save"}
							</Button>
							<Button sx={{ml: 1}}  size="large" variant="contained" onClick={handleSaveAndValidate} disabled={saving}>
								{saving ? "Saving and Validating" : "Save and Validate"}
							</Button>
						</Box>
					}
				/>
			</DialogActions>
		</Dialog>
	)
}

export default QuestionEditor
