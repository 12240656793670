import React, {useEffect, useMemo, useState}     from 'react'
import {Box, CircularProgress, Fade, Typography} from '@mui/material'

const LoadingWithMessages = ({messages, duration}) => {
	const [currentMessageIndex, setCurrentMessageIndex] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentMessageIndex((prevIndex) => Math.min(++prevIndex, messages.length-1))
		}, duration)

		return () => clearInterval(interval)
	}, [messages, duration])

	const currentMessage = useMemo(()=>{
		if(currentMessageIndex < 0 || currentMessageIndex >= messages.length){
			return null
		}
		return messages[currentMessageIndex]

	}, [currentMessageIndex, messages])

	return (
		<Box justifyContent="center" py={3} sx={{textAlign: 'center'}}>
			<CircularProgress/>
			<Box mt={2}>
				<Fade in timeout={200}>
					<Typography variant="h6" key={currentMessageIndex}>
						{currentMessage}
					</Typography>
				</Fade>
			</Box>
		</Box>
	)
}

export default LoadingWithMessages
