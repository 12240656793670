import {useMemo}                      from "react"
import {Box} from "@mui/material"
import {Hearing, Verified}            from "@mui/icons-material"

const Validations = ({validations}) => {
	return <Box display={"flex"}
	            flexDirection="row"
	            alignItems={"center"}>
		{
			validations && validations.map((v,i) => <ValidationIcon key={`val-${i}`} validation={v} />)
		}
	</Box>
}

const ValidationIcon = ({validation}) => {
	const iconColor = useMemo(()=>{
		if(validation.is_valid) {
			if(validation.validation_type ===  `ACCURACY`) {
				return "primary"
			}
			else if(validation.validation_type === `TTS`) {
				return "secondary"
			}
			else {
				return "none"
			}
		}
		else {
			return "warning"
		}
	}, [validation])


	if(validation.validation_type ===  `ACCURACY`) {
		// noinspection JSValidateTypes
		return <Verified color={iconColor} fontSize="small" />
	}
	else if(validation.validation_type === `TTS`) {
		// noinspection JSValidateTypes
		return <Hearing color={iconColor} fontSize="small" />
	}
	else {
		return null
	}
}

export default Validations