import {useMemo}                from "react"
import {Box, Tooltip} from "@mui/material"
import {Hearing, Verified}      from "@mui/icons-material"

const ValidationSummary = ({validations}) => {
	return <Box display={"flex"} flexDirection="row" alignItems={"center"}>
		{
			validations && validations.map((v,i) => <ValidationSummaryChip key={`val-${i}`} validation={v} />)
		}
	</Box>
}

const ValidationSummaryChip = ({validation}) => {

	const iconColor = useMemo(()=>{
		if(validation.invalid > 0) {
			return "warning"
		}
		else {
			if(validation.type ===  `ACCURACY`) {
				return "primary"
			}
			else if(validation.type === `TTS`) {
				return "secondary"
			}
			else {
				return null
			}
		}
	}, [validation])


	const icon = useMemo(()=>{

		if(validation.type ===  `ACCURACY`) {
			// noinspection JSValidateTypes
			return <Verified color={iconColor} fontSize="small" />
		}
		else if(validation.type === `TTS`) {
			// noinspection JSValidateTypes
			return <Hearing color={iconColor} fontSize="small"/>
		}
		else {
			return null
		}
	}, [validation, iconColor])


	let tt = `${validation.type}: ${validation.valid} valid`
	if (validation.invalid > 0) {
		tt += `, ${validation.invalid} invalid`
	}

	return <Tooltip title={tt}>
		{icon}
	</Tooltip>


}

export default ValidationSummary