import React, {useMemo, useState}                                            from 'react'
import {useOutletContext}                                                    from 'react-router-dom'
import {
	Alert,
	AlertTitle,
	Badge,
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControlLabel,
	Link,
	List,
	Menu,
	MenuItem,
	Switch,
	useTheme,
}                                                                            from '@mui/material'
import Grid                                                                  from '@mui/material/Unstable_Grid2' // Grid version 2
import {doDelete, doPost}                                                    from "../../util/do-fetch"
import {logger}                                                              from "../../util/log-utils"
import QuestionListItem                                                      from "./QuestionListItem"
import QuestionEditor                                                        from "./QuestionEditor"
import TopicsTree                                                            from "./TopicsTree"
import {findTopicInTopics}                                                   from "../../util/mini"
import {CopyAllOutlined, DeleteOutline, MoreVert, MoveUp, Refresh, Verified} from "@mui/icons-material"
import LeftRight                                       from "../LeftRight"
import {getAccuracyValidation, getAllQuestionsInTopic} from "../../util/admin"

const log = logger("ProductQuestions", 1)

function ProductQuestions({working, onStartWorking, onStopWorking}) {
	const theme = useTheme()
	const context = useOutletContext()
	const startWorking = onStartWorking || context?.onStartWorking
	const stopWorking = onStopWorking || context?.onStopWorking

	const {product, reloadProduct} = useOutletContext()
	const [topicId, setTopicId] = useState(null)
	const [question, setQuestion] = useState(null)
	const [expandQuestions, setExpandQuestions] = useState(false)
	const [generatingQuestions, setGeneratingQuestions] = useState(Boolean(product.generate_questions_at))
	const [selectedQuestions, setSelectedQuestions] = useState({})
	const [menuAnchorEl, setMenuAnchorEl] = useState(null)
	const [moveMode, setMoveMode] = useState(false)

	const topic = useMemo(() => {
		return topicId && product && product.topics && findTopicInTopics(product.topics, topicId)
	}, [product, topicId])

	const selectedQuestionsCount = useMemo(() => {
		return Object.values(selectedQuestions).filter(isSelected => isSelected).length
	}, [selectedQuestions])

	const renderTopicDetail = (t) => {
		if (!t) return null

		return <Box display={"flex"} flexDirection={"column"}>
			<LeftRight
				left={
					<Box>
						<h3 style={{padding: 0, margin: 0}}>{t.name}</h3>
						<small>
							[{t.id}] {(t.questions && t.questions.length) || 0} questions
							, {t.target_question_count} targeted,
							Select:
							<Link sx={{ml: 1, cursor: "pointer"}} onClick={selectAll}>All</Link> |
							<Link sx={{ml: 0.5, cursor: "pointer"}} onClick={selectNone}>None</Link>

							{
								working &&
								<Box pt={1} ml={2} display={"inline"} sx={{fontSize: "14px"}}><CircularProgress size={14} sx={{ mr: 0.5 }} /> Working...</Box>
							}

						</small>
					</Box>
				}
				right={
					<Box display={"flex"}>
						<FormControlLabel
							control={
								<Switch checked={expandQuestions}
								        onChange={() => setExpandQuestions(!expandQuestions)}
								/>
							}
							label="Expand"
						/>

						<Badge badgeContent={selectedQuestionsCount}
						       invisible={selectedQuestionsCount < 1}
						       color="error"
						       max={9999}
						>
							<Button
								variant={"outlined"}
								disabled={selectedQuestionsCount === 0 || working}
								onClick={(event) => setMenuAnchorEl(event.currentTarget)} // Set anchor for the popup menu
							>
								<MoreVert />
							</Button>
						</Badge>

						<Menu
							anchorEl={menuAnchorEl}
							open={Boolean(menuAnchorEl)}
							onClose={() => setMenuAnchorEl(null)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
						>
							<MenuItem
								disabled={selectedQuestionsCount === 0}
								onClick={async () => {
									await copySelectedQuestionsJSON()
									setMenuAnchorEl(null)
								}}
							>
								<CopyAllOutlined sx={{ mr: 1 }} />
								Copy JSON
							</MenuItem>
							<MenuItem
								disabled={selectedQuestionsCount === 0}
								onClick={() => {
									setMoveMode(true)
									setMenuAnchorEl(null)
								}}
							>
								<MoveUp sx={{ mr: 1 }} />
								Move
							</MenuItem>
							<Divider/>
							<MenuItem
								disabled={selectedQuestionsCount === 0 || working}
								onClick={async () => {
									await validateSelectedQuestions()
									setMenuAnchorEl(null)
								}}
							>
								<Verified sx={{ mr: 1 }} />
								Validate Questions
							</MenuItem>
							<Divider/>
							<MenuItem
								disabled={selectedQuestionsCount === 0 || working}
								onClick={async () => {
									await regenerateSelectedQuestions()
									setMenuAnchorEl(null)
								}}
							>
								<Refresh sx={{ mr: 1 }} />
								Regenerate Questions
							</MenuItem>
						</Menu>

					</Box>
				}
			/>
			<Divider sx={{mt: 1}}/>
			<div>
				{
					renderQuestions(t.questions) ||
					(
						t.is_leaf &&
						<Box textAlign={"center"} pt={4}>
							<Button color={"error"}
							        size={"large"}
							        variant={"contained"}
							        onClick={handleDeleteTopic}
							>
								<DeleteOutline sx={{mr: 1}}/>
								Delete Empty Topic
							</Button>
						</Box>
					)
				}
			</div>
		</Box>
	}

	const renderQuestions = (questions) => {
		if (!questions || questions.length === 0) return null
		return (
			<List>
				{questions.map((question, i) => (
					<QuestionListItem
						key={`qli-${question.id}`}
						question={question}
						index={i}
						onSelect={(isSelected) => handleSelectQuestion(question.id, isSelected)}
						selected={!!selectedQuestions[question.id]} // Check if the question is selected
						expanded={expandQuestions}
						onEdit={handleEditQuestion}
					/>
				))}
			</List>
		)
	}

	const getSelectedQuestionIds = () => {
		return Object.keys(selectedQuestions).filter(id => selectedQuestions[id])
	}

	const getSelectedQuestions = () => {
		return topic && topic.questions.filter(question => selectedQuestions[question.id])
	}

	const handleEditQuestion = (question) => {
		setQuestion(question)
	}

	const handleDeleteTopic = async () => {
		try {
			const response = await doDelete(`/admin/products/${product.id}/topic/${topic.id}`)
			if(!response.ok) {
				// noinspection ExceptionCaughtLocallyJS
				throw response.statusText
			}
			reloadProduct()
		} catch (err) {
			alert(`Failed to delete topic: ${err}`)
			log.error(err)
		}
	}

	const handleSelectQuestion = (questionId, isSelected) => {
		setSelectedQuestions(prevSelected => ({
			...prevSelected,
			[questionId]: isSelected
		}))
	}

	const handleCancelEditQuestion = () => {
		setQuestion(null)
	}

	const regenerateSelectedQuestions = async () => {
		startWorking()

		const options = {
			body: JSON.stringify({ questionIds: getSelectedQuestionIds()} )
		}
		const response = await doPost(`/admin/products/${product.id}/topic/${topic.id}/regenerate-questions`, options)
		if (response.ok) {
			await reloadProduct()
			setSelectedQuestions({})
		}
		else {
			console.error('Failed to update question', await response.text())
		}

		stopWorking()
	}

	const validateSelectedQuestions = async () => {
		return validateQuestions(getSelectedQuestionIds())
	}

	const validateQuestions = async (questionIds) => {
		startWorking()

		// if questionIds provided use them, otherwise use getSelectedQuestionIds
		const options = {
			body: JSON.stringify({ questionIds: questionIds } )
		}
		const response = await doPost(`/admin/products/${product.id}/validate-questions`, options)
		if (response.ok) {
			await reloadProduct()
			setSelectedQuestions({})
		}
		else {
			console.error('Failed to validate questions', await response.text())
		}

		stopWorking()
	}

	const moveSelectedQuestions = async (moveToTopic) => {
		// eslint-disable-next-line no-restricted-globals
		const userConfirmed = confirm(`Are you sure you want to move the selected questions to\n${moveToTopic.name}?`)

		if (userConfirmed) {
			const options = {
				body: JSON.stringify({ questionIds: getSelectedQuestionIds()} )
			}
			const response = await doPost(`/admin/products/${product.id}/move-questions/${moveToTopic.id}`, options)
			if (response.ok) {
				await reloadProduct()
				setSelectedQuestions({})
			}
			else {
				console.error('Failed to update question', await response.text())
			}
		}
	}

	const handleMoveTopicUp = async (topicToMoveUp) => {
		// eslint-disable-next-line no-restricted-globals
		const userConfirmed = confirm(`Are you sure you want to move this topic up one level?`)

		if (userConfirmed) {
			const options = {
				body: JSON.stringify({ questionIds: getSelectedQuestionIds()} )
			}
			const response = await doPost(`/admin/products/${product.id}/topic/${topicToMoveUp.id}/move-up`, options)
			if (response.ok) {
				await reloadProduct()
			}
			else {
				console.error('Failed to move topic', await response.text())
			}
		}
	}

	const handleValidateTopic = async (topicToValidate, onlyValidateInvalidOrNotValidated) => {
		let filter = onlyValidateInvalidOrNotValidated && ((q) => {
			const val = getAccuracyValidation(q)
			return !val || !val.is_valid
		  })
		const qs = getAllQuestionsInTopic(topicToValidate, [], filter)
		const qids = qs.map(q => q.id)
		// eslint-disable-next-line no-restricted-globals
		if(qids && qids.length > 0) {
			await validateQuestions(qids)
		}
		else {
			alert('No questions to validate.')
		}
	}

	const copySelectedQuestionsJSON = () => {
		const qs = getSelectedQuestions()
		const str = JSON.stringify(qs, null, 2)
		navigator.clipboard.writeText(str)
	}

	const selectAll = () => {
		const allSelected = topic && topic.questions.reduce((acc, question) => {
			acc[question.id] = true
			return acc
		}, {})
		setSelectedQuestions(allSelected)
	}

	const selectNone = () => {
		setSelectedQuestions({})
	}

	const handleSaveQuestion = async (prevQuestion, updatedQuestion) => {
		const options = {
			body: JSON.stringify(updatedQuestion)
		}
		const response = await doPost(`/admin/products/${product.id}/topic/${topic.id}/question/${prevQuestion.id}`, options)
		if (response.ok) {
			await reloadProduct()
			setQuestion(null)
		}
		else {
			// Handle the error case
			console.error('Failed to update question', await response.text())
		}
	}

	const handleSaveAndValidateQuestion = async (prevQuestion, updatedQuestion) => {
		await handleSaveQuestion(prevQuestion, updatedQuestion)
		await validateQuestions([prevQuestion.id])
	}

	const handleDeleteQuestion = async(question) => {
		const response = await doDelete(`/admin/products/${product.id}/topic/${topic.id}/question/${question.id}`)
		if (response.ok) {
			await reloadProduct()
			setQuestion(null)
		}
		else {
			// Handle the error case
			console.error('Failed to delete question', await response.text())
		}
	}

	const handleToggleGenerateQuestions = async () => {
		try {
			const toggle = !generatingQuestions
			setGeneratingQuestions(toggle) // proactively set it to true, and revert if it fails

			const response = await doPost(`/admin/products/${product.id}/generate-questions?enable=${toggle}`)
			const data = await response.json()

			if (!data.success) {
				setGeneratingQuestions(!toggle) // set it back
				alert("Error enabling question generator.  Try refreshing the page.")
				// noinspection ExceptionCaughtLocallyJS
				throw new Error("Error updating product")
			}
		}
		catch (err) {
			log.error(err)
		}
	}

	return <>
		<Grid xs={12} container>
			<Grid xs={4}>
				<Box style={{
					backgroundColor: theme.palette.background.paper,
					borderRadius: "5px",
					border: "1px solid " + theme.palette.divider,
					overflowY: "auto",
					maxHeight: "calc(100vh - 250px)",
					height: "100%"
				}}>
					<Box display="flex"
					     flexDirection={"column"}
					     justifyContent="space-between"
					     alignItems="center"
					     sx={{borderBottom: '1px solid #ddd', p: 2, width: '100%'}}
					>
						<Box display={"flex"} width={"100%"} flexDirection={"row"} justifyContent={"flex-start"} alignContent={"center"}>
							<FormControlLabel
								control={
									<Switch size={"small"} checked={generatingQuestions}
									        onChange={handleToggleGenerateQuestions}
									/>
								}
								label="Question Generator"
							/>
						</Box>
					</Box>
					<TopicsTree
						topics={product && product.topics}
						selectedTopic={topic}
						onClick={async (t) => {
							if(moveMode) {
								await moveSelectedQuestions(t)
								setMoveMode(false)
								setSelectedQuestions({})
							}
							setTopicId(t.id)
						}}
						onMoveTopicUp={handleMoveTopicUp}
						onValidateTopic={handleValidateTopic}
					/>
				</Box>
			</Grid>
			<Grid xs={8} sx={{pl: 4}}>
				{
					moveMode &&
					<Grid xs={12} container>
						<Alert variant={"outlined"}
						       severity={"warning"}
						       sx={{width: "100%", mb: 2}}
						       onClose={()=>setMoveMode(false)}
						       action={
							       <Button
								       color="inherit" size="small"
								       onClick={()=>setMoveMode(false)}
							       >
								       Cancel
							       </Button>
						       }
						>
							<AlertTitle>Move Questions Enabled</AlertTitle>
							Select a different topic below to move {selectedQuestionsCount} question{selectedQuestionsCount!==1 && `s`}
						</Alert>
					</Grid>
				}
				{topic && renderTopicDetail(topic)}
			</Grid>
		</Grid>
		{
			question &&
			<QuestionEditor
				question={question}
				onSave={handleSaveQuestion}
				onSaveAndValidate={handleSaveAndValidateQuestion}
				onCancel={handleCancelEditQuestion}
				onDelete={handleDeleteQuestion}
			/>
		}

	</>
}

export default ProductQuestions
