import {doGet}             from "./do-fetch"
import {Divider, MenuItem} from "@mui/material"
import React, {useMemo}    from "react"
import getSupabase         from "./supabase-utils"


const ADMIN_ACTIONS = [
	"Users", "Categories", "Products",
	"Packages", "Licenses", "Videos",
	"AI Personalities", "Topic Job Queue",
	"App Config", "Bad JSON", "Palette",
	"Check Access"
].map(n => ({
		url:  `/admin/${n.toLowerCase().replace(/\s+/g, '-')}`,
		label: n
	}))

const fetchLLMModels = async () => {
	const response = await doGet(`/admin/models/llm`)
	const data = await response.json()
	return data
}

const fetchTTSModels = async () => {
	const response = await doGet(`/admin/models/tts`)
	const data = await response.json()
	return data
}

const fetchVoices = async () => {
	const response = await doGet(`/admin/voices`)
	const data = await response.json()
	return data
}

const fetchCategories = async () => {
	const response = await doGet(`/admin/categories`)
	const data = await response.json()
	return data
}

const fetchSignedMediaUrl = async (apiMediaUrl) => {
	const response = await doGet(apiMediaUrl)
	return await response.text()
}

const getAllQuestionsInTopic = (topic, allQuestions, filter) => {
	const topics = topic.topics || []
	allQuestions = allQuestions || []
	filter = filter || (()=>{ return true })
	if(topic.is_leaf) {
		allQuestions = (topic.questions || []).filter(filter)
	}
	else {
		for (let i = 0; i < topics.length; i++) {
			const topic = topics[i]
			if (topic.questions) {
				topic.questions.forEach((q, i) => {
					filter(q) && allQuestions.push(q)
				})
			}
			else if (topic.topics) {
				getAllQuestionsInTopic(topic.topics, allQuestions, filter)
			}
		}
	}
	return allQuestions
}

const getAccuracyValidation = (question) => {
	const val = question &&
		question.validations &&
		question.validations.length > 0 &&
		question.validations.find(v => v.validation_type === 'ACCURACY')
	return val
}

const storeAdminSession = async () => {
	const { data, error } = await getSupabase().auth.getSession()

	if (error) {
		throw 'Error fetching admin session: ' + error
	}

	if (data && data.session) {
		localStorage.setItem('admin_session', JSON.stringify(data.session))
		console.log('Admin session stored successfully')
	}
}

const restoreAdminSession = async () => {
	const adminSession = localStorage.getItem('admin_session')

	if (adminSession) {
		const session = JSON.parse(adminSession)

		const { error } = await getSupabase().auth.setSession({
			access_token: session.access_token,
			refresh_token: session.refresh_token,
		})

		if (error) {
			throw 'Error restoring admin session: ' + error
		}

		// clean up
		localStorage.removeItem('admin_session')
	}
	else {
		throw 'No admin session found in localStorage'
	}
}

export {fetchTTSModels, fetchLLMModels, fetchVoices, fetchSignedMediaUrl, fetchCategories,
	getAllQuestionsInTopic, getAccuracyValidation, storeAdminSession, restoreAdminSession, ADMIN_ACTIONS}